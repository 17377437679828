import $ from 'jquery';

let menuLinks = $('nav a');

menuLinks.click(function() {

    let li = $(this).parent();
    let lis = li.siblings();

    if(!li.hasClass('active')) {
        li.addClass('active');
        lis.each((index, element) => $(element).removeClass('active'));
    }
});
