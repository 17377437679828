import $ from 'jquery';
import lightgallery from 'lightgallery';
// import particlesJS from './particlesJS/particles.min.js';
import pbSlideshow from './pb-slideshow.js';
import scrollingAnimation from '../classes/scrollinganimation.js';
import scrollToTop from './scrolling-animation.js';
import functions from './functions.js';
import matchHeight from '../classes/match-height.js';
import contact from './contact.js';
import dynamicform from './dynamicform.js';

function mount(event = false) {

    let pierre = document.querySelector('.pierre');
    let icones = document.querySelector('.icones');
    let slideshow = document.querySelector('.slideshow');
    let scrollingAnimationElement = document.querySelectorAll('[data-scrolling-animation]');
    let slider = document.querySelector('#slider');
    let galerie = $('.galerie');

    //Create new Event : pageFullyLoaded
    functions.createEvent('pageFullyLoaded');

    //Links active
        require('./menu.js');
    //Offcanvas
        require('./offcanvas.js');
    //Contact (animations sur la page contact)
        contact();
    //Dynamic form : input et textarea dynamiques
        dynamicform();

    //Animation lors du scroll
    if(scrollingAnimationElement.length) {

        new scrollingAnimation(scrollingAnimationElement, {
            threshold: [0.3, 0.7]
        });
    }

    //Particles
    if((typeof particlesJS != "undefined") && slider && (document.documentElement.clientWidth > 1000))
        particlesJS.load('slider', 'assets/js/modules/particlesJS/particlesjs-nasa-config.json');

    //Slider accueil
    if(slideshow)
        slideshow = new pbSlideshow(slideshow);

    if(pierre) {

        new scrollingAnimation(pierre, {
            duration: 2000,
            delay: 700,
            preserve: true,
            threshold: [0.3, 0.7],
            callback: () => {

                setTimeout(() => {

                    if(icones) {

                        let children = $(icones).find('> div');
                        children.addClass('animate');
                        icones.classList.add('active');
                    }
                    pierre.classList.add('equal');
                }, 100);
            }
        });
    }

    //Match height grid match
    let gridMatch = document.querySelectorAll('.grid-match');
    if(gridMatch && gridMatch.length) {
        new matchHeight(document.querySelectorAll('.grid-match'), {
            breakPoint: 650
        });
    }

    //Galerie images
    if(galerie && galerie.length) {

        let gallery = galerie.lightGallery({
            thumbnail: false,
            animateThumb: false,
            showThumbByDefault: false,
            getCaptionFromTitleOrAlt: false,
            download: false,
            galleryId: 1
        });
    }


    return {particlesJS, pierre, slideshow};
}

export default mount;
