    import Mount from './modules/mount.js';
    import Unmount from './modules/unmount.js';
    import Swup from 'swup';
    import PortfolioLoaderTheme from './modules/swup/themes/SwupPortfolioLoaderTheme/entry.js';
    import functions from './modules/functions.js';
    import contact from './modules/contact.js';
    // import SwupSlideTheme from '@swup/slide-theme';
    // import SwupScriptsPlugin from '@swup/scripts-plugin';
    // import SwupBodyClassPlugin from '@swup/body-class-plugin';
    // import SwupOverlayTheme from '@swup/overlay-theme';

    //Require web components
    require('./components/tooltip.js');

    //Initialisation des scripts lors du premier chargement
    let objects = Mount();
    let swupLoader = null;
    //Déclenchement (en manuel, plus tard ça sera Swup qui s'en chargera) de l'événement : "pageFullyLoaded"
    functions.dispatchEvent('pageFullyLoaded');

    //Swup
    if(!swupLoader) {

        swupLoader = new Swup({
            containers: ['main'],
            animateHistoryBrowsing: true,
            cache: false, //À désactiver pour les sites dynamiques
            plugins: [
                new PortfolioLoaderTheme({
                    mainElement: 'main'
                }),
                // new SwupScriptsPlugin({ //Recharge tous les scripts à chaque changement de page
                //     optin: false //Manuel ou auto ? data-swup-reload-script
                // }),
                // new SwupBodyClassPlugin()
            ]
        });

        //Déclenché au début de l'animation
        swupLoader.on('clickLink', function() {
            Unmount(objects);
        })

        //Déclenché après le chargement de la page
        swupLoader.on('contentReplaced', function() {
            objects = Mount(); //Réinitialisation des scripts après chaque chagement de page
        });

        //Déclenché juste après le remplacement du contenu de la page
        // swupLoader.on('willReplaceContent', function() {
        // })
    }
