
    class MatchHeight {

        constructor(parent, options = null) {

            this.parent = parent;
            if(!this.parent) return null;

            //Set option
            this.options = {
                breakPoint: 1000
            }

            //User options
            if(options) {
                for(let i in options)
                    this.options[i] = options[i];
            }

            //Responsive
            window.addEventListener('resize', this.debounce(() => {
                if(this.getWindowWidth() > this.options.breakPoint)
                    this.init();
                else //Reset all styles
                    this.resetHeight();
            }, 200));

            //Init
            return (this.getWindowWidth() > this.options.breakPoint) ? this.init() : null;
        }

        init() {

            this.parent.forEach((element) => { //Pour chaque contenaire (parent)

                this.maxHeight = 0;
                let target = element.dataset.target || false;
                let node = element.querySelectorAll(target);

                if(node && node.length > 0) {

                    this.getTheTallest(node);
                    if(this.maxHeight > 0)
                        this.setHeight(node, this.maxHeight);
                }
            });

            return this.parent;
        }

        getTheTallest(node) { //Pour chaque enfant

            node.forEach((element) => {

                //Reset height for correct calcul
                element.style.minHeight = 0;

                let styles = getComputedStyle(element, null);
                let height = parseInt(styles['height']);

                if(height > this.maxHeight)
                    this.maxHeight = height;
            });
        }

        setHeight(node, height) {

            node.forEach((element) => {

                let styles = getComputedStyle(element, null);
                if(styles['height'] != height+'px')
                    element.style.minHeight = height+'px';
            });
        }

        resetHeight() {

            this.parent.forEach((element) => { //Pour chaque contenaire (parent)

                let target = element.dataset.target || false;
                let node = element.querySelectorAll(target);

                if(node && node.length > 0) {

                    node.forEach((element) => {
                        element.style.minHeight = 0;
                    });
                }
            });
        }

        getWindowWidth() {
            return window.innerWidth;
        }

        debounce(callback, delay) { //Attend un certain delais pour exécuter une fonction

            var timer, // Static variable
                delay = delay || 0;

            return function() {

                var self = this, //Get context
                    args = arguments;

                clearTimeout(timer);
                timer = setTimeout(function() {
                    return callback.apply(self, args);
                }, delay);
            }
        }

        throttle(callback, delay) { //Espace les appels à une fonction dans un interval de temps

            var last = 0, //Dernier appel à la fonction
                delay = delay || 0,
                timer;

            return function() {

                var now = +new Date();
                var self = this, //Get context
                    args = arguments;

                if(now < (last) + (delay)) { //On est encore dans le delais (on refixe le timer)
                    clearTimeout(timer);
                    timer = setTimeout(function() {
                        callback.apply(self, args);
                        last = now;
                    }, delay);
                }
                else { //Exécution immédiate de la fonction car hors délais
                    callback.apply(self, args);
                    last = now;
                }
            }
        }
    }

    export default MatchHeight;
