import Theme from '@swup/theme';
import styles from './theme.less';
import {fetch} from 'swup/lib/helpers';
import functions from '../../../../../../js/modules/functions.js';

export default class PortfolioLoaderTheme extends Theme {

	constructor(options) {

		super();
		this.parser = new DOMParser();
		this.options = options;

		if(!this.options.mainElement)
			this.options.mainElement = '#swup'
	}

	mount() {

		// apply imported styles
		this.applyStyles(styles);
		let randomNumber = null;
		let html = document.documentElement;

		this.swup.on('clickLink', (event) => {

			let overlay = document.querySelector('#overlay-bubble');
			let chargement = document.querySelector('#chargement');
			randomNumber = Math.floor(Math.random() * 4 ) + 1; //Nombre entre 1 et 4

			overlay.style.top = event.clientY+'px';
			overlay.style.left = event.clientX+'px';

			//Remove all classes
			chargement.removeAttribute('class');
			overlay.removeAttribute('class');

			overlay.classList.add('style'+randomNumber, 'animated');
			chargement.classList.add('animated');
			//Overflow sur balise html
			html.classList.add('hidden');

		});

		this.swup.on('willReplaceContent', (event) => {

			let overlay = document.querySelector('#overlay-bubble');
			let chargement = document.querySelector('#chargement');

			//Hide offcanvas overlay
			functions.closeOffcanvas();

			setTimeout(() => { //Texte : Chargement...

				chargement.classList.add('done');
				setTimeout(() => {
					chargement.classList.remove('animated', 'done');
				}, 200);
			}, 1700);

			setTimeout(() => { //Overlay

				overlay.classList.add('done'); //Faire disparaître progressivement l'overlay

				setTimeout(() => {

					overlay.classList.remove('style'+randomNumber, 'animated', 'done');
					setTimeout(() => { //Rétablissement de la barre de scrolling pour la fenêtre
						html.classList.remove('hidden');
						//Gestion des événements personnalisés
						functions.dispatchEvent('pageFullyLoaded');
					}, 600);
				}, 500);
			}, 2000);
		});

		//Si même page : on annule tout
		this.swup.on('samePage', (event) => {

			//Hide offcanvas overlay
			functions.closeOffcanvas();

			let overlay = document.querySelector('#overlay-bubble');
			let chargement = document.querySelector('#chargement');

			overlay.classList.remove('style'+randomNumber, 'animated', 'done');
			chargement.classList.remove('animated');
			//Rétablissement de la barre de scrolling pour la fenêtre
			html.classList.remove('hidden');
		});

		// add classname to
		this.addClassName(this.options.mainElement, 'fade'); //Mot à la fin de la classe

		// add HTML structure to body if needed
		this.applyHTML('<div id="overlay-bubble"></div>');
		this.applyHTML('<div id="chargement">Chargement...</div>');
	}

	unmount() {

	}
}

// this.swup.loadPage = (params, rr) => {

	// let requete = fetch(null, (object) => {
	//
	// 	let response = object.response;
	// 	let domContent = this.parser.parseFromString(response, 'text/html');
	// 	let mainElement = domContent.querySelector(this.options.mainElement);
	//
	// 	this.addClassName('html', 'is-animating');
	// 	this.removeClassName('html', 'is-animating');
	// 	console.log(mainElement.innerHTML)
	// });
// };
