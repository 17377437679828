
    import functions from '../modules/functions.js';

    class ScrollingAnimation {

        constructor(element, options) {

            this.elements = element;
            this.options = {
                threshold: [0.3,0.7],
                thresholdEnd: [],
                visibled: 0.3,
                hidden: 0.3,
                duration: 0,
                delay: 0,
                preserve: false,
                callback: false,
                begin: false,
                custom: null
            };

            //User options
            for(var i in options)
				this.options[i] = options[i];

            //If only one element
            if('length' in this.elements == false)
                this.elements = [this.elements];

            //Disabled on mobile devises
            if(this.isMobile()) {
                this.initMobile(this.elements);
                return false;
            }

            //Initialise animation on elements or element
            for(var i = 0; i < this.elements.length; i++)
                this.init(this.elements[i]);

            return this.elements;
        }

        init(element) {

            let threshold = (element.getAttribute('data-threshold') !== null) ? element.getAttribute('data-threshold').split(',').map(function(e) {
                return parseFloat(e, 10);
            }) : this.options.threshold;
            let object = this;
            let lastScrollTop = 0;
            let scrollDirection = null;
            let preserve = element.getAttribute('data-preserve') || this.options.preserve;

            element.ScrollingAnimation = {
                observer: new IntersectionObserver(function(entries) {

                    var wasAbove = false;
                    var _self = this;
                    //Scroll direction
                    var st = window.pageYOffset || document.documentElement.scrollTop;
                    if (st > lastScrollTop)
                        scrollDirection = 'down';
                    else
                        scrollDirection = 'up';

                   lastScrollTop = st;
                   //Scroll direction

                    entries.forEach(function(e) {

                        if(e.isIntersecting == false) return; //For Chrome !
                        var visibility = e.intersectionRatio;
                        var element = e.target;
                        e.scrollDirection = scrollDirection;

                        if(typeof object.options.custom == 'function')
                            object.options.custom.call(_self, {element: element, instance: object, visibility, entry: e});
                        else
                            object.animate(element, e);
                    });
                }, {
                    threshold: threshold //A partir de quel(s) pourcentage(s) de visiblité doit-on déclencher l'événement sur l'élément
                })
            };

            element.ScrollingAnimation.observer.observe(element); //Créé l'événement
        }

        animate(element, observer) {

            var _self = this;
            var scrollDirection = observer.scrollDirection;
            var duration = element.getAttribute('data-duration') || this.options.duration;
            var delay = element.getAttribute('data-delay') || this.options.delay;
            var preserve = element.getAttribute('data-preserve') || this.options.preserve;
            var callback = element.getAttribute('data-callback') || this.options.callback;
            var begin = element.getAttribute('data-begin') || this.options.begin;
            var direction =  element.getAttribute('data-direction') || this.options.direction;
            var animation =  element.getAttribute('data-animation');

            if(!animation) {
                animation = this.options.animation;
            }
            else if(animation.indexOf('{') > -1) {
                animation = JSON.parse(element.getAttribute('data-animation'));
            }

            var end_animation = element.getAttribute('data-end-animation');
            var end_delay = parseInt(element.getAttribute('data-end-delay'), 10);
            var remove = element.getAttribute('data-remove');
            var visibility = observer.intersectionRatio;
            var done = false;

            setTimeout(() => { //delay

                //Fonction Ã  exÃ©cuter au dÃ©but
                if(typeof begin == 'function')
                    begin.call(element, element);

                //Animation de l'élément
                if(typeof animation == 'object') {  //Animation des styles en JS

                    if(preserve && element.classList.contains('done')) return;

                    for(let property in animation) { //Pour chaque style CSS à animer

                        let style = animation[property];
                        if(typeof style == "number")
                            style = style.toString();

                        let match = style.match(/([\d\.])+/g);

                        match.forEach(function(value, index) {

                            let currentValue = element.style[property].match(/([\d\.])+/g);
                            let newValue = value * visibility;
                            element.style[property] = style.replace(value, newValue);
                        });
                    }

                    if(preserve && visibility == 1) {
                        element.classList.add('done');
                        done = true;
                    }
                }
                else if(animation != '') { //Classe CSS à appliquer

                    visibility = Math.floor(visibility * 100) / 100; //Arrondi à deux chiffres après la virgule
                    animation = animation.split(',');
                    animation.push('active');

                    if(preserve && visibility == 1) {
                        element.classList.add('done');
                        done = true;
                    }

                    if(!element.classList.contains(...animation) && (visibility >= this.options.visibled)) {
                        element.classList.add(...animation);
                        done = true;
                    }
                    else if((element.classList.contains(...animation)) && (preserve == false) && (visibility < this.options.hidden)) {
                        element.classList.remove(...animation);
                    }
                }

                if(end_animation || callback != '') {

                    setTimeout(function() { //duration : callback de fin d'animation

                        if(animation.length > 0 && preserve == false)
                            element.classList.remove(...animation);
                        //Animation de fin si existante
                        if(end_animation) {

                            end_animation = end_animation.split(',')
                            setTimeout(function() {
                                element.classList.add(...end_animation);
                            }, end_delay);
                        }

                        if(typeof callback == 'function')
                            callback.apply(element);
                        else if(typeof functions[callback] == 'function')
                            functions[callback].call();

                    }, duration);
                }

                //Supprimer l'événement
                if(preserve && done)
                    ScrollingAnimation.unObserve(element);

            }, delay);

            delay += duration;

            if(remove) {

                setTimeout(function() {

                    let parent = element.parentNode;
                    parent.removeChild(element);
                }, delay + duration + end_delay);
            }

        }

        static unObserve(element) {

            if('length' in element) {
                element.forEach((elem) => {
                    if('ScrollingAnimation' in elem)
                        elem.ScrollingAnimation.observer.unobserve(elem);
                });
            }
            else {
                if('ScrollingAnimation' in element)
                    element.ScrollingAnimation.observer.unobserve(element);
            }
        }

        isMobile() {
            return document.documentElement.clientWidth <= 1000;
        }

        initMobile(elements) {

            for(let i = 0; i < elements.length; i++) {

                let element = elements[i];
                element.classList.add('active');
                let callback = element.getAttribute('data-callback') || this.options.callback;

                if(typeof callback == 'function')
                    callback.apply(element);
                else if(typeof functions[callback] == 'function')
                    functions[callback].call();
            }

        }

    }

    export default ScrollingAnimation;
