import functions from './functions.js';
import AjaxRequest from './ajax-request-function.js';

let process = false;

function processAjax(form) {

    let data = serialize(form);
    let XHR = new AjaxRequest(
        'post',
        window.location.pathname,
        data+'&core=ajax&layout=ajax',
        false,
        false,
        {
            start: (instance) => {

                functions.showOverlay();
            },
            end: (instance) => {

                let html = instance.getHTML();
                let status = instance.getStatus();

                setTimeout(() => { //Temporisation de l'overlay pour un peu d'animation

                    functions.hideOverlay(false, true); //Hide "Chargement"
                    let overlay = document.querySelector('#overlay-left');

                    if(status == 200 && html) { //Requête bien enregistrée, donc on affiche que c'est bon
                        //Supression des valeurs
                        resetValues(form);
                    }
                    //Erreur
                    else {  }

                    if(html) { //Affichage de la réponse
                        overlay.innerHTML = html;
                        //Hide overlay by user's action
                        overlay.addEventListener('click', () => { functions.hideOverlay(true); });
                    }
                    else {
                        functions.hideOverlay(true); //Hide overlay if no content
                    }

                    //Refaire apparaître la lettre sur la page
                    resetForm(form);
                    process = false;
                }, 1500);
            }
        }
    );
}

function resetValues(form) {

    for(let i = 0; i < form.elements.length; i++) {
        if(form.elements[i].type == 'text' || form.elements[i].type == 'textarea' || form.elements[i].type == 'email')
            form.elements[i].value = '';
    }
}

function resetForm(form) {
    form.parentElement.classList.remove('send', 'drop-email');
}

function serialize(form) {

    let params = [];
    for(let i = 0; i < form.elements.length; i++)
        params.push(encodeURIComponent(form.elements[i].name) + '=' + encodeURIComponent(form.elements[i].value));

    return params.join('&');
}

export default function() {

    var form = document.querySelector('form#contact');
    if(form) {

        functions.addEventElement(form, 'pageFullyLoaded', () => { //Quand la page finie de se charger : script d'affichage du formulaire
            form.parentElement.classList.add('show');
        });

        form.addEventListener('submit', function(e) {

            e.preventDefault();
            let parent = this.parentElement;
            if(process) return false;

            if(!parent.classList.contains('send')) {

                process = true;
                parent.classList.add('send');
                setTimeout(function() {

                    parent.classList.add('drop-email');
                    setTimeout(() => { //Laisser l'animation de la lettre se terminer
                        processAjax(form);
                    }, 900 - 450); //900-400
                }, 2200);
            }
        });
    }
};
