
    function pbSlideshow(slideshow) {

        var _self = this;
        this.slideshow = slideshow;
        this.duration = 5000;
        this.interval = false;
        if(!this.slideshow) throw Error('Aucun élément trouvé');
            this.imgs = this.slideshow.querySelectorAll('img');
        if(!this.imgs) throw Error('Aucune image trouvée');
        this.debounce = false;
        this.stopOnHover = true;

        this.startAnimation();

        if(this.stopOnHover) {

            this.slideshow.addEventListener('mouseenter', function() {

                _self.debounce = setTimeout(function() {
                    _self.stopAnimation.call(_self);
                }, 2000);
            });

            this.slideshow.addEventListener('mouseleave', function() {

                if(_self.debounce != false) {
                    clearTimeout(_self.debounce);
                    _self.startAnimation.call(_self);
                }
            });
        }

        return _self;
    }

    pbSlideshow.prototype = {

        setAnimation: function() {

            var _self = this;
            setTimeout(function() {

                _self.active = _self.slideshow.querySelector('img.active') || _self.imgs[0];
                var next = _self.active.nextElementSibling || _self.imgs[0];

                if(!next || _self.active == next) return false;

                //Remove class active
                _self.removeClass(_self.active);
                //Add class active to the next element
                _self.addClass(next);

                //Boucle
                // requestAnimationFrame.call(_self, _self.setAnimation);
            }, 0);
        },
        startAnimation: function() {

            var _self = this;
            clearInterval(this.interval);
            this.interval = setInterval(function() {
                _self.setAnimation();
            }, _self.duration);
        },
        stopAnimation: function() {
            var _self = this;
            return clearInterval(_self.interval);
        },
        // TODO: A améliorer !
        addClass: function(element) {
            element.className = 'active';
        },
        removeClass: function(element) {
            element.className = '';
        }
    };

    export default pbSlideshow;
