
    import $ from 'jquery';
    import functions from './functions.js';

    function scrollingAnimation(element, callback) {

        var threshold = [0.1];

        for (var i = 0; i < element.length; i++) {

            var threshold = (element[i].getAttribute('data-threshold') !== null) ? element[i].getAttribute('data-threshold').split(',').map(function(e) {
                return parseFloat(e, 10);
            }) : [0.5];

            var observer = new IntersectionObserver(function(entries) {

                var _self = this;
                entries.forEach(function(e) {

                    if(e.isIntersecting == false) return; //For Chrome !
                    var visibility = e.intersectionRatio;
                    var element = e.target;
                    var threshold = element.getAttribute('data-threshold');

                    callback.call(element, _self);
                });
            }, {
                threshold: threshold //A partir de quel(s) pourcentage(s) de visiblité doit-on déclencher l'événement sur l'élément
            });

            observer.observe(element[i]); //Créé l'événement
        }
    }

    scrollingAnimation.prototype.animation = function(element, duration, delay, preverse, callback) {

        $(element).each(function() {

            var _self = $(this);
            var animation = _self.data('animation');

            if(_self.data('delay'))
                delay += _self.data('delay');

            var end_animation = _self.data('end-animation');
            var end_delay = parseInt(_self.data('end-delay'), 10);
            var remove = _self.data('remove');

            setTimeout(function() {

                _self.addClass(animation).addClass('active');

                    setTimeout(function() {

                        if(_self.index() + 1 != element.length) {
                            if(!preverse)
                                _self.removeClass(animation).removeClass('active');
                        }

                        if(end_animation) {

                            setTimeout(function() {
                                _self.addClass(end_animation);
                            }, end_delay);
                        }

                        if(typeof callback == 'function')
                            callback.apply(_self);
                    }, duration);
            }, delay);

            delay += duration;

            if(remove) {
                setTimeout(function() {
                    _self.remove();
                }, delay + duration + end_delay);
            }
        });
    };

    export default scrollingAnimation;
