
    class ToolTip extends HTMLElement {

        constructor() {

            super();
            this.classElement = 'tooltip';
            // this.shadowDOM = this.attachShadow({mode: 'open'});
        }

        connectedCallback() {

            this.addStyle();
            //Get initial value
            this.content = this.getAttribute('texte');

            //Set eventlistener
            this.addEventListener('mouseenter', this.eventHoverCallback);
            this.addEventListener('mouseleave', this.eventLeaveCallback);
            //For mobile
            this.addEventListener('touchstart', this.eventHoverCallback);
        }

        disconnectedCallback() {

            //Remove eventlistener
            this.removeEventListener('mouseenter', this.eventHoverCallback);
            this.removeEventListener('mouseleave', this.eventLeaveCallback);
            this.removeEventListener('touchstart', this.eventHoverCallback);
            //Remove tooltip
            this.removeTooltip();
        }

        eventHoverCallback(e) {

            e.preventDefault();
            e.stopPropagation();
            if(this.removeTooltip()) return;

            let tooltip = this.setTooltip(this.content);
            //Position de l'élément vis-à-vis de la fenêtre navigateur
            let coordonneesElement = e.target.getClientRects()[0];
            //Nombre de pixels scrollés
            let scrollTop = window.scrollY;
            let scrollLeft = window.scrollX;
            //Détermination de la position de la tooltip
            let y = coordonneesElement.y + scrollTop;
            let x = coordonneesElement.x + scrollLeft;
            //Calcul de la hauteur de la tooltip
            let tooltipHeight = tooltip.offsetHeight;
            //Positionnement de la tooltip
            tooltip.style.top = y - (tooltipHeight / 2 - coordonneesElement.height / 2) + 'px'; //Centrage vertical
            tooltip.style.left = x + coordonneesElement.width + 'px';
        }

        eventLeaveCallback(e) {
            this.removeTooltip();
        }

        setTooltip(content) {

            //Add tooltip to body tag
            let tooltip = document.createElement('div');
            tooltip.setAttribute('class', this.classElement);
            tooltip.innerHTML = content;
            document.body.appendChild(tooltip);

            return document.querySelector('.'+this.classElement);
        }

        removeTooltip() {

            let tooltip = document.querySelector('.'+this.classElement);
            if(tooltip)
                return document.body.removeChild(tooltip);
        }

        attributeChangedCallback(name, oldValue, newValue) {

            if(name === 'texte' && (typeof this.content !== "undefined")) {

                if(newValue) { //Déclenchement de l'animation
                    this.content = newValue;
                }
            }
        }

        static get observedAttributes() {
            return ['texte'];
        }

        addContent(content) {

            if(typeof content == 'string') {
                this.innerHTML += content;
            }
            else if(typeof content == 'object') {
                if(typeof content.forEach == 'function') {
                    content.forEach((element) => {
                        this.appendChild(element);
                    });
                }
                else {
                    this.appendChild(content);
                }
            }
        }

        addStyle() {

            let style = `
                <style>
                    tool-tip {
                        display: var(--tooltip-display, inline-block);
                    }
                    .tooltip {
                        position: absolute;
                        background: var(--background-tooltip, #444);
                        color: var(--color-tooltip, #fff);
                        font-size: var(--font-size-tooltip, 0.9em);
                        padding: 5px 10px;
                        box-sizing: border-box;
                        border-radius: 5px;
                        z-index: 500;
                    }
                </style>`;

            this.addContent(style);
        }
    }

    customElements.define('tool-tip', ToolTip);
