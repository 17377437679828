    import functions from './functions.js';

    let offcanvas = document.querySelector('a.offcanvas');
    let offcanvasOverlay = document.querySelector('#offcanvas');
    let offcanvasClose = offcanvasOverlay.querySelector('.close');
    let html = document.documentElement;

    if(offcanvas && offcanvasOverlay) {

        //Lien offcanvas du menu haut
        offcanvas.addEventListener('click', function(e) {
            e.preventDefault();
            offcanvasOverlay.classList.add('active');
            html.classList.add('hidden');
        });

        //Click sur le offcanvas de l'overlay
        offcanvasClose.addEventListener('click', function(e) {

            e.preventDefault();
            functions.closeOffcanvas();
            html.classList.remove('hidden');
        });
    }
