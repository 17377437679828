import $ from 'jquery';

export default function() {

    var form = $('.dynamic-form');
    var input = $('.input', form);
    var input_contenaire = '.contenaire-input';
    var focus_class = 'focus';
    var too_much_class = 'too-much';
    var full_class = 'full';
    var textarea = $('.textarea');
    var limite = $('input[name="limite"]').val();
    var restant = $('.restant span');
    var supp = [8,0];
	var interrupteur = $('.interrupteur');

    if(!form) return null;

    input.focus(function() {

       var parent = $(this).parents(input_contenaire);
       parent.addClass(focus_class);
    });

    input.blur(function(e) {
		eval_input($(this), e);
    });

    //On window load
    input.each(function() {
        eval_input($(this) );
    });

	//TEXTAREA
	textarea.blur(function() { //Lorsque l'on qui le champ
		var content = $(this).val();
		var value = content.length;
		var difference = limite - value;
	});

	//APPARITION DIV
	interrupteur.click(function() {

		var __class__ = $(this).data('target-class');
		var target = $('.'+__class__);
		//Other
		var inputs = $('.contenaire-input', target);
		var delay = 100;
		var plus = 300;

		if(target.is(':hidden')) { //Si target est caché

			target.stop().slideDown(400, function() {
				//Callback
				animation;
			});
		}
		else { //Si target est visible, alors on le cache

			animation;
			target.stop().delay(delay).slideUp(400);
		}
		delay = 100;
	});

    //FUNCTIONS
    function eval_input(element, even) {

        var value = $.trim(element.val());
        var parent = element.parents(input_contenaire);
        var tagName = element[0].tagName.toLowerCase();

        if(even && even.type == "blur") { //Si on quitte le champs

            if(!value)
                parent.removeClass(full_class);
             else
                parent.addClass(full_class);

            if(tagName === 'textarea') {
                if(value.length == 0)
                    parent.removeClass(focus_class);
            }
            else {
                parent.removeClass(focus_class);
            }
        }
        else {
            if(value)
                parent.addClass(full_class);
            else
                parent.removeClass(full_class);
        }
    }

};
