
    import nivoSlider from './nivoSlider/jquery.nivo.slider.js';
    import particlesJS from 'particles.js';

    let functions = {

        startSlider() {
            nivoSlider('.nivoSlider').nivoSlider({
                effect: 'sliceUpRight',
                controlNav: false,
                prevText: '<img src="assets/images/realisations-slider/nav/prev.png" alt="prev" />',
                nextText: '<img src="assets/images/realisations-slider/nav/next.png" alt="next" />'
            });
        },
        stopSlider() {
            nivoSlider('.nivoSlider').nivoSlider({});
            nivoSlider('.nivoSlider').remove();
        },
        // starParticles() {
        //     particlesJS.load('competences', 'assets/js/module/particlesJS/particlesjs-config.json');
        // },
        scrollToTop(e) {
            e.preventDefault();
            nivoSlider('html, body').animate({scrollTop: 0}, {duration: 1000}); //easeInOutExpo
        },
        closeOffcanvas() {

            let offcanvasOverlay = document.querySelector('#offcanvas');
            if(offcanvasOverlay && offcanvasOverlay.classList.contains('active')) {

                offcanvasOverlay.classList.add('hide');
                setTimeout(() => offcanvasOverlay.classList.remove('active', 'hide'), 500);
            }
        },
        createEvent(eventName) {

            window.events = !window.events ? new Object() : window.events;

            events[eventName] = document.createEvent('Event');
            events[eventName].initEvent(eventName);
            events[eventName].elements = new Set();
        },
        removeEvents() {

            if(!events) return null;
            for(let eventName in events) { //On parcour tous les événements créés

                events[eventName].elements.forEach((element) => { //Pour chacun des élements rattaché à l'événement
                    element.removeEventListener(eventName, element.callBackEvent[eventName]);
                });
            }

            delete window.events;
        },
        addEventElement(element, eventName, callback) {

            if(!events[eventName]) //Si l'événement n'existe pas
                functions.createEvent(eventName);

            if(!element.callBackEvent)
                element.callBackEvent = new Object();

            //On stocke le callback pour pouvoir le supprimer plus tard
            element.callBackEvent[eventName] = callback;

            element.addEventListener(eventName, element.callBackEvent[eventName]);
            events[eventName].elements.add(element);
        },
        dispatchEvent(eventName = false) {

            if(eventName && events[eventName]) {

                events[eventName].elements.forEach((element) => {
                    element.dispatchEvent(events[eventName]);
                });
            }
            else if(events) {

                for(let i in events) {
                    events[i].elements.forEach((element) => {
                        element.dispatchEvent(events[i]);
                    });
                }
            }
        },
        showOverlay(className = 'animated') {

            let randomNumber = Math.floor(Math.random() * 4 ) + 1; //Nombre entre 1 et 4

            let overlay = document.querySelector('#overlay-left');
            let chargement = document.querySelector('#chargement');

            if(!overlay) {

                let divOverlay = document.createElement('div');
                divOverlay.setAttribute('id', 'overlay-left');
                document.body.appendChild(divOverlay);
                overlay = document.querySelector('#overlay-left');
            }

            chargement.removeAttribute('class');
            overlay.removeAttribute('class');

            setTimeout(() => {
                overlay.classList.add('style'+randomNumber, className);
                chargement.classList.add('animated');
            }, 50);

            return overlay;

        },
        hideOverlay(overlay = false, chargement = false) {

            if(overlay) {

                let overlay = document.querySelector('#overlay-left');
                overlay.classList.add('done'); //Faire disparaître progressivement l'overlay
                setTimeout(() => { //Remove overlay-left
                    if(document.querySelector('#overlay-left'))
                        document.body.removeChild(overlay);
                }, 800);
            }

            if(chargement) {
                let chargement = document.querySelector('#chargement');
                chargement.classList.remove('animated');
                chargement.classList.add('done');
            }

            return overlay;
        }
    }

    export default functions;
