    import functions from './functions.js';
    import scrollingAnimation from '../classes/scrollinganimation.js';

    function unmount(elements = {}) {

        //Destroy all custom events
        functions.removeEvents();

        //Unobserve intersection observer
        let scrollingAnimationElement = document.querySelectorAll('[data-scrolling-animation]');
        scrollingAnimation.unObserve(scrollingAnimationElement);

        //Pareil pour pierre
        if(elements.pierre)
            scrollingAnimation.unObserve(elements.pierre);

        //Arrêt du slideshow
        if(elements.slideshow)
            elements.slideshow.stopAnimation();

        //Arrêt du slider
        functions.stopSlider();
    }

    export default unmount;
